import { TextField, Button, Box, Heading } from '@mediahuis/chameleon-react';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FeatureFormTypeLinks from './FeatureFormTypeLinks';
import secureApiCall from '../../utils/secureApiCall';
import BrandSelect from '../inputs/BrandSelect';
import LanguageSelect from '../LanguageSelect';
import { notifyError, notifySuccess } from '../Toasts';

const MarkdownContainer = styled(Box)`
  padding: 16px;
  margin-top: 5px;
  background-color: #fafafa;
`;

const FeatureForm = ({
  editing = false,
  apiUrl,
  initialValues = {},
  onCancel,
  onEditSuccess,
}) => {
  const feature = initialValues;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: feature.name || '',
    brand: feature.brand || '',
    description: feature.description || '',
    negativeDescription: feature.negativeDescription || '',
  });
  const history = useHistory();

  // Overwrite state if parent gives new data.
  // E.g. when fetching data in new language
  useEffect(() => {
    setData(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // In case user tries to go second step in creating feature wizard
  // without having chosen a brand.
  useEffect(() => {
    if (!feature?.brand) {
      history.push('/features');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    secureApiCall(apiUrl, {
      method: editing ? 'PUT' : 'POST',
      data: JSON.stringify({
        ...data,
        id: feature.id,
      }),
      params: {
        'api-version': '2',
      },
      onSuccess: response => {
        setIsLoading(false);
        if (!editing) {
          history.push(`/features/edit/${response.data.id}`);
        } else if (onEditSuccess) {
          onEditSuccess(response.data);
        }
        notifySuccess({
          message: `Successfully ${editing ? 'edited' : 'created '} a feature.`,
        });
      },
      onError: errorMessage => {
        setIsLoading(false);
        notifyError({
          status:
            errorMessage?.response?.request?.status &&
            errorMessage.response.request.status,
          message: errorMessage?.response?.data.errors
            ? errorMessage.response.data.errors[0]
            : errorMessage.message,
        });
      },
    });
  };

  if (!feature?.brand) {
    return null;
  }

  return (
    <>
      <LanguageSelect
        options={POSSIBLE_LANGUAGES[feature.brand]}
        isEditing={editing}
        brand={feature?.brand}
      />
      <Box mb={5}>
        <TextField
          id="name"
          label="Name"
          name="name"
          datatestid="name"
          value={data.name}
          onChange={e => setData({ ...data, name: e.target.value })}
        />
      </Box>
      <Box mb={5}>
        <TextField
          id="description"
          label="Description"
          name="description"
          datatestid="description"
          optionalLabel={MH_BRAND === BRANDS.LUX && 'Translatable'}
          value={data.description ? data.description : ''}
          onChange={e => setData({ ...data, description: e.target.value })}
        />
        {data.description && (
          <MarkdownContainer>
            <MarkdownRender source={data.description} />
          </MarkdownContainer>
        )}
      </Box>
      <Box mb={5}>
        <TextField
          id="negativeDescription"
          label="Negative Description"
          name="negativeDescription"
          datatestid="negativeDescription"
          value={data.negativeDescription ? data.negativeDescription : ''}
          optionalLabel={MH_BRAND === BRANDS.LUX && 'Translatable'}
          onChange={e =>
            setData({ ...data, negativeDescription: e.target.value })
          }
        />
        {data.negativeDescription && (
          <MarkdownContainer>
            <MarkdownRender source={data.negativeDescription} />
          </MarkdownContainer>
        )}
      </Box>
      <Box mb={8}>
        <BrandSelect disabled value={data.brand} />
      </Box>
      <Box>
        <Button
          datatestid="save-formula-button"
          color="primary"
          isLoading={isLoading}
          type="submit"
          onClick={onSubmit}
          width="full"
        >
          Save
        </Button>
        {onCancel && (
          <Button appearance="secondary" width="full" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Box>

      <Box>
        <Heading fontFamily="primary" level={4}>&nbsp;</Heading>
        <Heading fontFamily="primary" level={4}>
          Feature is these subscription types:
        </Heading>
        <FeatureFormTypeLinks
          featureFormTypeLinks={data.subscriptionTypes}
        />
      </Box>

    </>
  );
};

export default FeatureForm;
